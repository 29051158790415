import Checkbox from '../../../atoms/Checkbox/UI/Checkbox';
import styles from './countingDaysCheckboxes.module.css';

interface CountingDaysCheckboxesProps {
    workDaysValue?: boolean;
    handleChangeWorkDaysValue?: () => void;
    holyDaysValue?: boolean;
    handleChangeHolyDaysValue?: () => void;
}

function CountingDaysCheckboxes({
    workDaysValue,
    handleChangeWorkDaysValue,
    holyDaysValue,
    handleChangeHolyDaysValue,
}: CountingDaysCheckboxesProps) {
    return (
        <div className={styles.checkboxWrapper}>
            <Checkbox
                label="Учитывать рабочие дни"
                type="checkbox"
                id="workdays"
                checked={workDaysValue}
                onChange={handleChangeWorkDaysValue}
            />
            <Checkbox
                label="Учитывать выходные дни"
                type="checkbox"
                id="weekend"
                checked={holyDaysValue}
                onChange={handleChangeHolyDaysValue}
            />
        </div>
    );
}

export default CountingDaysCheckboxes;
