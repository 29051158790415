import { getClassName } from 'utils/helpers';

import styles from './timeLag.module.css';

interface TimeLagProps {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function TimeLag({ onChange }: TimeLagProps) {
    return (
        <div className={styles.wrapper}>
            <div className={getClassName(styles.text, 'unselectable')}>
                Выберите временной лаг
            </div>
            <div className={styles.timesWrapper}>
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="lastDay"
                    value="0"
                    onChange={onChange}
                />
                <label
                    className={getClassName(
                        'form-check-label',
                        styles.label,
                        'unselectable'
                    )}
                    htmlFor="lastDay"
                >
                    последние полные сутки (с 00:00 до 23:59)
                </label>
            </div>
            <div className={styles.timesWrapper}>
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="lastWeek"
                    value="1"
                    onChange={onChange}
                />
                <label
                    className={getClassName(
                        'form-check-label',
                        styles.label,
                        'unselectable'
                    )}
                    htmlFor="lastWeek"
                >
                    средние сутки за неделю
                </label>
            </div>
            <div className={styles.timesWrapper}>
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="lastMonth"
                    value="2"
                    onChange={onChange}
                />
                <label
                    className={getClassName(
                        'form-check-label',
                        styles.label,
                        'unselectable'
                    )}
                    htmlFor="lastMonth"
                >
                    средние сутки за месяц
                </label>
            </div>
        </div>
    );
}

export default TimeLag;
