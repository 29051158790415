import { getClassName } from 'utils/helpers';

import styles from './checkbox.module.css';

type CheckboxProps = {
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    id?: string;
    defaultValue?: string;
    type?: string;
    disabled?: boolean;
    label?: string;
    checked?: boolean;
};

function Checkbox({
    className = '',
    checked,
    onChange,
    type = 'text',
    disabled = false,
    defaultValue,
    id,
    label = '',
}: CheckboxProps) {
    return (
        <div className={getClassName('form-check', className, styles.wrapper)}>
            <input
                className="form-check-input"
                type={type}
                checked={checked}
                id={id}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={onChange}
            />
            <label
                className={getClassName('form-check-label', styles.label)}
                htmlFor={id}
            >
                {label}
            </label>
        </div>
    );
}

export default Checkbox;
