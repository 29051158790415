import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
/* import { Button } from 'react-bootstrap'; */
import { useNavigate } from 'react-router-dom';

import { useLazyGetResultsQuery } from '../requests/requests';

interface ResponseInterface {
    id: number;
    created_on: string;
    input_data: {
        flag: string;
        holyDays: true;
        statType: boolean;
        workDays: boolean;
        zone_id: number;
        amount_proggramms: number;
        period_time: number;
        smoothing: boolean;
        laneFlows: {};
    };
    result: {};
    print_to_front: boolean;
}

const useModelingResultsPage = () => {
    const navigate = useNavigate();
    const [dataResults, setDataResults] = useState();
    const [currentPage, setCurrentPage] = useState(0);

    const [getResults, results] = useLazyGetResultsQuery();

    const handleGetResults = useCallback(
        async (page) => {
            await getResults({
                size: 10,
                page: page + 1,
            });
        },
        [getResults]
    );

    useEffect(() => {
        handleGetResults(currentPage);
    }, [handleGetResults, currentPage]);

    useEffect(() => {
        if (results) {
            setDataResults(results.data?.response?.results);
        }
    }, [results]);

    const handleGo = (record: ResponseInterface) => {
        if (record.input_data.flag === 'local') {
            navigate(
                `/mesoscopic-modeling/local-calculations/${record.input_data.zone_id}?lag=${record.input_data.statType}&workDays=${record.input_data.workDays}&holyDays=${record.input_data.holyDays}&saveDataId=${record.id}`
            );
        } else if (record.input_data.flag === 'global') {
            navigate(
                `/mesoscopic-modeling/coordinated-control/${record.input_data.zone_id}?lag=${record.input_data.statType}&workDays=${record.input_data.workDays}&holyDays=${record.input_data.holyDays}&saveDataId=${record.id}`
            );
        }
    };

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'created_on',
            key: 'created_on',
            id: 'created_on',
            sorter: false,
            render: (text: string) => (
                <div>{dayjs(`${text}Z`).format('DD-MM-YYYY HH:mm:ss')}</div>
            ),
        },
        {
            title: 'Тип моделирования',
            dataIndex: 'search_type',
            key: 'search_type',
            id: 'search_type',
            sorter: false,
            render: (text: string, record: ResponseInterface) => (
                <div>
                    {record.input_data?.flag === 'local'
                        ? 'Локальное'
                        : 'Координированное'}
                </div>
            ),
        },
        {
            title: 'Идентификатор объекта',
            dataIndex: 'zone_id',
            key: 'zone_id',
            id: 'zone_id',
            sorter: false,
            render: (text: string, record: ResponseInterface) => (
                <div>{record.input_data?.zone_id}</div>
            ),
        },
        /* {
            title: 'Передача в ПСУ',
            dataIndex: 'psu',
            key: 'psu',
            id: 'psu',
            sorter: false,
            render: (text: string, record: ResponseInterface) =>
                record.input_data?.flag === 'local' && (
                    <Button>Загрузить</Button>
                ),
        }, */
        {
            title: 'Подробности',
            dataIndex: '#',
            key: '#',
            id: '#',
            sorter: false,
            render: (text: string, record: ResponseInterface) => (
                <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => handleGo(record)}
                    onClick={() => handleGo(record)}
                >
                    Подробнее
                </div>
            ),
        },
    ];
    return { columns, results, dataResults, currentPage, setCurrentPage };
};

export default useModelingResultsPage;
