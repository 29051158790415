import { useEffect, useRef, useState } from 'react';

import useCreateMap from './useCreateMap';
import useLayers from './useLayers';
import useUpdateToken from './useUpdateToken';

type UseMapType = {
    isChecking?: boolean;
};

const useMap = ({ isChecking = false }: UseMapType) => {
    const MapRef = useRef<HTMLDivElement | null>(null);
    const [updateTokenFunc, setUpdateTokenFunc] = useState<null | Function>(
        null
    );

    const { mapValue } = useCreateMap({ MapRef });

    const { tokenMap, getTokenForMap } = useUpdateToken(updateTokenFunc);

    const {
        overlay,
        OverlayRef,
        MapPopupContentRef,
        onUpdateToken,
        isVisibleResetBtn,
        handlerBtnClick,
    } = useLayers({
        mapValue,
        isChecking,
        tokenMap,
        getTokenForMap,
    });

    useEffect(() => {
        setUpdateTokenFunc(onUpdateToken);
    }, [onUpdateToken]);

    const handlerClosePoUp = () => {
        overlay?.setPosition(undefined);
    };

    const handlerResetLayer = () => {
        handlerBtnClick();
    };

    return {
        MapRef,
        OverlayRef,
        handlerClosePoUp,
        handlerResetLayer,
        isVisibleResetBtn,
        MapPopupContentRef,
        tokenMap,
        mapValue,
    };
};

export default useMap;
