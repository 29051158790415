import { useAppDispatch } from 'hooks/defaulthooks';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { setIsAuthorization } from '../../../organisms/authWrapper/reducers/authorization';
import { useGetTokenMutation } from '../requests/requests';

const useAuthPage = () => {
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const navigate = useNavigate();
    const [getToken, params] = useGetTokenMutation();

    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const code = urlParams.get('code');
        if (code) {
            getToken(code);
        }
    }, [getToken, search]);

    useEffect(() => {
        if (params.isSuccess && params.status === 'fulfilled' && params.data) {
            dispatch(setIsAuthorization({ isAuth: true }));
            localStorage.setItem('token', params.data.access_token);
            localStorage.setItem('refresh_token', params.data.refresh_token);
            navigate('/');
        }
    }, [dispatch, navigate, params]);

    const handleClick = () => {
        window.location.href = `${process.env.REACT_APP_AUTH_LINK}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;
    };
    return {
        handleClick,
    };
};

export default useAuthPage;
