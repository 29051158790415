import {
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import {
    BaseQueryApi,
    BaseQueryFn,
    QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { setIsAuthorization } from 'components/organisms/authWrapper/reducers/authorization';
import { openNotification } from 'utils/helpers';

interface RefreshResultData {
    data: {
        access_token: string;
        expires_in: number;
        refresh_token: string;
        token_type: string;
    };
}

interface ErrorData {
    data: {
        response: string;
        status: string;
    };
}

export const interceptor = async (
    result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
    args: string | FetchArgs,
    baseQuery: BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
    >,
    api: BaseQueryApi,
    extraOptions: {}
) => {
    if (result.error && result.error.status === 503) {
        openNotification('error', (result.error as ErrorData).data?.response);
    }
    if (
        result.error &&
        (result.error.status === 401 || result.error.status === 302)
    ) {
        const refreshResult = await baseQuery(
            {
                url: `${process.env.REACT_APP_AUTH_LINK}/oauth/token`,
                method: 'POST',
                body: {
                    refresh_token: localStorage.getItem('refresh_token'),
                    client_id: process.env.REACT_APP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_SECRET_KEY,
                    grant_type: 'refresh_token',
                },
            },
            api,
            extraOptions
        );
        if (refreshResult.error && refreshResult?.error?.status === 401) {
            localStorage.clear();
            api.dispatch(setIsAuthorization({ isAuth: false }));
        }
        if (refreshResult?.data) {
            localStorage.setItem(
                'token',
                (refreshResult as RefreshResultData).data.access_token
            );
            localStorage.setItem(
                'refresh_token',
                (refreshResult as RefreshResultData).data.refresh_token
            );
            const newData = await baseQuery(args, api, extraOptions);
            return newData;
        }
    }
    return result;
};
