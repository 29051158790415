import { Button } from 'react-bootstrap';

import styles from './buttonsCalculationsBlock.module.css';

type ButtonsCalculationsBlockProps = {
    isVisibleCancelButton?: boolean;
    isVisibleModelButton?: boolean;
    isVisibleSaveButton?: boolean;
    handleShowResults?: () => void;
    handleSave?: () => void;
    handleReset?: () => void;
};

function ButtonsCalculationsBlock({
    isVisibleCancelButton = true,
    isVisibleModelButton = true,
    isVisibleSaveButton = true,
    handleShowResults,
    handleSave,
    handleReset,
}: ButtonsCalculationsBlockProps) {
    const saveModelingResult = !!JSON.parse(
        localStorage.getItem('permissions') || '[]'
    ).find((word: string) => word.indexOf('save') !== -1);

    return (
        <div className={styles.buttonWrapper}>
            {isVisibleModelButton && (
                <Button
                    variant="dark"
                    className={styles.modelButton}
                    onClick={handleShowResults}
                >
                    Смоделировать
                </Button>
            )}
            {isVisibleSaveButton && saveModelingResult && (
                <Button
                    variant="dark"
                    className={styles.saveButton}
                    onClick={handleSave}
                >
                    Сохранить
                </Button>
            )}
            {isVisibleCancelButton && (
                <Button
                    variant="danger"
                    className={styles.resetButton}
                    onClick={handleReset}
                >
                    Сбросить
                </Button>
            )}
        </div>
    );
}

export default ButtonsCalculationsBlock;
