import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IsOpenBurgerMenuStoreInterface {
    isOpen: boolean;
}

const initialState: IsOpenBurgerMenuStoreInterface = {
    isOpen: false,
};

export const isOpenBurgerMenuInfo = createSlice({
    name: 'isOpen',
    initialState,
    reducers: {
        setIsOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
    },
});

export const { setIsOpen } = isOpenBurgerMenuInfo.actions;

export default isOpenBurgerMenuInfo.reducer;
