import { useAppDispatch, useAppSelector } from 'hooks/defaulthooks';
import { useEffect } from 'react';

import { setIsAuthorization } from '../reducers/authorization';

const useAuthWrapper = () => {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector((state) => state.authorization.isAuth);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(setIsAuthorization({ isAuth: true }));
        }
    }, [dispatch, isAuth]);

    return { isAuth };
};

export default useAuthWrapper;
