import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IsAuthorizationStoreInterface {
    isAuth: boolean | null;
}

const initialState: IsAuthorizationStoreInterface = {
    isAuth: null,
};

export const isAuthInfo = createSlice({
    name: 'isAuthorization',
    initialState,
    reducers: {
        setIsAuthorization: (
            state,
            action: PayloadAction<IsAuthorizationStoreInterface>
        ) => {
            state.isAuth = action.payload.isAuth;
        },
    },
});

export const { setIsAuthorization } = isAuthInfo.actions;

export default isAuthInfo.reducer;
