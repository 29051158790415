import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ZoneInterface {
    type: string;
    id: string | number;
    geometry_name: string;
    geometry: {
        type: string;
        coordinates: [number[]];
    };
    properties: {
        deleted: boolean;
        guid: string;
        description: string | null;
        name: string;
        create_time: string;
        author: number;
        area: number;
        zone_type: number;
    };
}

interface ZoneInitialStateInterface {
    zone: ZoneInterface | null;
}

const initialState: ZoneInitialStateInterface = {
    zone: null,
};

export const zone = createSlice({
    name: 'zone',
    initialState,
    reducers: {
        setZone: (state, action: PayloadAction<ZoneInterface>) => {
            state.zone = action.payload;
        },
    },
});

export const { setZone } = zone.actions;

export default zone.reducer;
