import { Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { Form } from 'react-bootstrap';

import styles from './sliderBlock.module.css';

const marks: SliderMarks = {
    5: '5',
    60: '60',
};

interface SliderBlockProps {
    value?: number;
    onChange?: (e: number) => void;
}

function SliderBlock({ value, onChange }: SliderBlockProps) {
    return (
        <>
            <div className={styles.subtitle}>
                С каким шагом рассчитывать сигнальный план?
            </div>
            <div className={styles.sliderWrapper}>
                <Slider
                    min={5}
                    max={60}
                    marks={marks}
                    defaultValue={15}
                    className={styles.slider}
                    value={value}
                    onChange={onChange}
                />
                <Form className={styles.form}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            readOnly
                            value={value}
                            className={styles.input}
                        />
                    </Form.Group>
                </Form>
            </div>
        </>
    );
}

export default SliderBlock;
