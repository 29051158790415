import { Spin, Table } from 'antd';
import ModalWindow from 'components/atoms/Modal/UI/Modal';
import ButtonsCalculationsBlock from 'components/molecules/buttonsCalculationsBlock/UI/buttonsCalculationsBlock';
import ConnectorsTable from 'components/molecules/connectorsTable/UI/connectorsTable';
import TrafficIntensityBlock from 'components/molecules/trafficIntensityBlock/UI/trafficIntensityBlock';
import TrafficIntensityBlockInputs from 'components/molecules/TrafficIntensityBlockInputs/UI/TrafficIntensityBlockInputs';
import ModelingOptionsBlock from 'components/organisms/modelingOptionsBlock/UI/modelingOptionsBlock';

import useLoggingPage from '../logic/useLocalResultsPage';
import styles from './loggingPage.module.css';

function LocalResultsPage() {
    const {
        allLanes,
        amountProgramsValue,
        amountProgramsChecked,
        columns,
        currentFlows,
        currentPlanResult,
        currentTrafficIntensity,
        handleChangeAmountProgramsChecked,
        handleChangeAmountProgramsValue,
        handleChangeCloseModal,
        handleChangeCurrentFlows,
        handleChangeTrafficIntensity,
        handleChangeSliderValue,
        handleChangeSmoothingValue,
        handleReset,
        handleSave,
        handleShowResults,
        mapImageRef,
        mapResults,
        modelingData,
        planResults,
        planResultsData,
        results,
        resultsColumns,
        saveDataId,
        savePlanResults,
        showModal,
        showResults,
        sliderValue,
        smoothingValue,
        summary,
    } = useLoggingPage();

    return (
        <Spin
            size="large"
            spinning={planResults.isLoading || savePlanResults.isLoading}
        >
            <div className={styles.mainWrapper}>
                <ModalWindow
                    show={showModal}
                    size="xl"
                    handleClose={handleChangeCloseModal}
                    title="Транспортная интенсивность"
                    body={
                        <div>
                            <TrafficIntensityBlockInputs
                                isChanged={saveDataId}
                                intensity={currentFlows}
                                onChange={handleChangeCurrentFlows}
                            />
                        </div>
                    }
                />
                <div className={styles.firstWrapper}>
                    {(!showResults ||
                        currentPlanResult.data?.response?.result) && (
                        <ConnectorsTable
                            columns={columns}
                            dataSource={allLanes}
                            isLoading={results.isLoading}
                        />
                    )}
                    {showResults && (planResultsData || currentPlanResult) && (
                        <TrafficIntensityBlock
                            intensity={
                                currentPlanResult.data?.response?.result
                                    ? currentPlanResult.data.response.result
                                    : planResultsData
                            }
                            onChange={handleChangeTrafficIntensity}
                            isWatch={currentPlanResult?.data}
                        />
                    )}
                    {currentTrafficIntensity && (
                        <>
                            <div className={styles.durationTitle}>
                                Длительность цикла - {summary}, сек
                            </div>
                            <div className={styles.title}>
                                Результаты моделирования
                            </div>
                            <Table
                                pagination={false}
                                columns={resultsColumns}
                                dataSource={modelingData}
                            />
                        </>
                    )}
                </div>
                <div className={styles.secondWrapper}>
                    <Spin
                        size="large"
                        wrapperClassName={styles.spinMap}
                        spinning={mapResults.isLoading}
                    >
                        <img className={styles.map} ref={mapImageRef} alt="" />
                    </Spin>
                    <div className={styles.modelingWrapper}>
                        <ModelingOptionsBlock
                            sliderValue={sliderValue}
                            handleChangeSliderValue={handleChangeSliderValue}
                            smoothingValue={smoothingValue}
                            handleChangeSmoothingValue={
                                handleChangeSmoothingValue
                            }
                            amountProgramsValue={amountProgramsValue}
                            amountProgramsChecked={amountProgramsChecked}
                            handleChangeAmountProgramsValue={
                                handleChangeAmountProgramsValue
                            }
                            handleChangeAmountProgramsChecked={
                                handleChangeAmountProgramsChecked
                            }
                        />
                        <ButtonsCalculationsBlock
                            isVisibleSaveButton={showResults && !saveDataId}
                            isVisibleModelButton={!showResults}
                            isVisibleCancelButton={!saveDataId}
                            handleShowResults={handleShowResults}
                            handleSave={handleSave}
                            handleReset={handleReset}
                        />
                    </div>
                </div>
            </div>
        </Spin>
    );
}

export default LocalResultsPage;
