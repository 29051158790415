import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { interceptor } from './interceptor';
import { mapInterceptor } from './mapInterceptor';

const baseQuery = fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (api.endpoint === 'getMap' || api.endpoint === 'getConnectors') {
        return mapInterceptor(result, args, baseQuery, api, extraOptions);
    }

    return interceptor(result, args, baseQuery, api, extraOptions);
};

export const emptySplitApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
});
