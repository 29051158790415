export const directions = {
    right: 'Направо',
    left: 'Налево',
    direct: 'Прямо',
};

export const typesTurn = {
    DEFAULT: 'Обычная',
    ALLOCATED: 'Выделенная',
};

export const typesPhase = {
    VEHICLE: 'Транспортная',
    CLEARING: 'Переходный интервал',
    PEDESTRIAN: 'Пешеходная',
};

export const hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
];
