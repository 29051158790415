import {
    useGetPlanMutation,
    useLazyGetConnectorsQuery,
    useLazyGetCurrentPlanQuery,
    useLazyGetMapQuery,
    useSavePlanMutation,
} from 'components/pages/localResultsPage/requests/requests';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { hours, typesPhase, typesTurn } from 'utils/constants';
import {
    checkForNumberValue,
    getDurationSum,
    getIntensity,
    openNotification,
} from 'utils/helpers';
import {
    Connector,
    Lane,
    LaneFlowResponse,
    LaneGroup,
    LaneGroupResponse,
    LaneResponse,
    LaneResult,
    PlanResponse,
    TrafficLightWithOptions,
} from 'utils/types';

import styles from '../UI/coordinatedControlResultsPage.module.css';

const useCoordinatedControlResultsPage = () => {
    const { search } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(search);
    const lag = urlParams.get('lag');
    const workDays = urlParams.get('workDays');
    const holyDays = urlParams.get('holyDays');
    const saveDataId = urlParams.get('saveDataId');

    const mapImageRef = useRef<HTMLImageElement | null>(null);

    const [allLanes, setAllLanes] = useState<LaneResult[]>([]);
    const [amountProgramsValue, setAmountProgramsValue] = useState('8');
    const [amountProgramsChecked, setAmountProgramsChecked] = useState(false);
    const [currentFlows, setCurrentFlows] = useState<LaneFlowResponse[]>([]);
    const [connectors, setConnectors] = useState<LaneResult[]>([]);
    const [currentIdTrafficLight, setCurrentIdTrafficLight] = useState<
        number | null
    >();
    const [currentTrafficIntensity, setCurrentTrafficIntensity] = useState('');
    const [lanesFlows, setLaneFlows] = useState<LaneFlowResponse[][]>([]);
    const [modelingData, setModelingData] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [sliderValue, setSliderValue] = useState(15);
    const [smoothingValue, setSmoothingValue] = useState(false);
    const [summary, setSummary] = useState(0);
    const [planResultId, setPlanResultId] = useState<number | null>(null);
    const [planResultsData, setPlanResultsData] = useState([]);
    const [trafficLightsWithOptions, setTrafficLightsWithOptions] = useState<
        TrafficLightWithOptions[]
    >([]);

    const [getMap, mapResults] = useLazyGetMapQuery();
    const [getPlan, planResults] = useGetPlanMutation();
    const [savePlan, savePlanResults] = useSavePlanMutation();
    const [getConnectors, results] = useLazyGetConnectorsQuery();
    const [getCurrentPlan, currentPlanResult] = useLazyGetCurrentPlanQuery();

    const handleChangeCloseModal = () => {
        setShowModal(false);
    };

    const getCurrentFlows = (currentId: number) => {
        lanesFlows.forEach((item: LaneFlowResponse[]) => {
            setCurrentFlows(
                item.filter(
                    (currentItem: LaneFlowResponse) =>
                        currentItem.lane === currentId
                )
            );
        });
    };

    const handleChangeCurrentFlows = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const buffer = [...currentFlows];
        if (
            buffer[index] &&
            e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)
        ) {
            buffer[index] = {
                ...buffer[index],
                count: e.target.value,
            };
            setCurrentFlows([...buffer]);
        }
        let flowArrayIndex: null | number = null;
        let currentIndex: null | number = null;
        lanesFlows.forEach((item: LaneFlowResponse[], curIndex: number) => {
            currentIndex = item.findIndex(
                (currentItem: LaneFlowResponse) =>
                    currentItem.lane === buffer[index].lane &&
                    currentItem.time === buffer[index].time
            );
            if (currentIndex != null) {
                flowArrayIndex = curIndex;
            }
        });
        const flowsBuffer = lanesFlows.map((item) =>
            item.map((soloItem: LaneFlowResponse) => ({ ...soloItem }))
        );
        if (
            flowArrayIndex != null &&
            currentIndex != null &&
            flowsBuffer[flowArrayIndex][currentIndex]
        ) {
            flowsBuffer[flowArrayIndex][currentIndex] = {
                ...buffer[index],
                count: Number(e.target.value),
            };
            setLaneFlows(flowsBuffer);
        }
    };

    const handleShowResults = () => {
        getPlan({
            zone_id: Number(id),
            flag: 'global',
            statType: Number(lag),
            workDays,
            holyDays,
            smoothing: smoothingValue,
            amount_proggramms: amountProgramsChecked
                ? Number(amountProgramsValue)
                : 0,
            period_time: sliderValue,
            flows: lanesFlows.flat(),
        });
    };

    const handleChangeAmountProgramsValue = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (
            Number(e.target.value) < 9 &&
            Number(e.target.value) > -1 &&
            !saveDataId
        ) {
            setAmountProgramsValue(checkForNumberValue(e.target.value));
        }
    };

    const handleChangeAmountProgramsChecked = () => {
        if (!saveDataId) {
            setAmountProgramsChecked(!amountProgramsChecked);
        }
    };

    const handleClickTrafficLights = (currentId: number) => {
        setCurrentIdTrafficLight(currentId);
    };

    const handleChangeTrafficIntensity = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCurrentTrafficIntensity(e.target.value);
    };

    const handleReset = () => {
        if (saveDataId) {
            navigate(
                `/mesoscopic-modeling/coordinated-control/${id}?lag=${lag}&workDays=${workDays}&holyDays=${holyDays}`
            );
        }
        getConnectors({
            id,
            statType: lag,
            workDays,
            holyDays,
        });
        setSliderValue(15);
        setAmountProgramsValue('8');
        setCurrentTrafficIntensity('');
        setSmoothingValue(false);
        setAmountProgramsChecked(false);
        setPlanResultsData([]);
        setShowResults(false);
        setPlanResultId(null);
        setCurrentFlows([]);
        setLaneFlows([]);
    };

    const handleSave = () => {
        if (planResultId) {
            savePlan({ result_id: planResultId });
        }
    };

    const handleShow = (currentId: number) => {
        setShowModal(true);
        getCurrentFlows(currentId);
    };

    const handleChangeSliderValue = (value: number) => {
        if (!saveDataId) {
            setSliderValue(value);
        }
    };

    const handleChangeSmoothingValue = () => {
        if (!saveDataId) {
            setSmoothingValue(!smoothingValue);
        }
    };

    useEffect(() => {
        if (savePlanResults.isSuccess) {
            openNotification(
                'success',
                'Результаты моделирования успешно сохранены'
            );
        }
    }, [savePlanResults]);

    useEffect(() => {
        if (saveDataId) {
            getCurrentPlan({ result_id: saveDataId });
        }
    }, [getCurrentPlan, saveDataId]);

    useEffect(() => {
        if (currentPlanResult.isSuccess) {
            setShowResults(true);
        }
        if (currentPlanResult.data?.response?.input_data.smoothing) {
            setSmoothingValue(
                currentPlanResult.data.response.input_data.smoothing
            );
        }
        if (currentPlanResult.data?.response?.input_data?.amount_proggramms) {
            setAmountProgramsValue(
                currentPlanResult.data.response.input_data.amount_proggramms
            );
            setAmountProgramsChecked(true);
        }
        if (currentPlanResult.data?.response?.input_data?.period_time) {
            setSliderValue(
                currentPlanResult.data.response.input_data.period_time
            );
        }
    }, [currentPlanResult]);

    useEffect(() => {
        if (
            currentPlanResult.isSuccess &&
            currentPlanResult.data?.response?.result?.response[
                currentTrafficIntensity
            ]
        ) {
            setSummary(
                getDurationSum(
                    currentPlanResult.data?.response.result.response[
                        currentTrafficIntensity
                    ] as PlanResponse
                )
            );
            setModelingData(
                Object.values(
                    currentPlanResult.data?.response.result.response[
                        currentTrafficIntensity
                    ]
                )
            );
        }
    }, [
        currentPlanResult.data?.response?.result,
        currentPlanResult.isSuccess,
        currentTrafficIntensity,
    ]);

    useEffect(() => {
        getConnectors({
            id,
            statType: lag,
            workDays,
            holyDays,
        });
        getMap({ id });
    }, [getConnectors, getMap, holyDays, id, lag, workDays]);

    useEffect(() => {
        if (planResults.isSuccess) {
            setPlanResultsData(planResults.data);
            setShowResults(true);
        }
    }, [planResults]);

    useEffect(() => {
        if (
            currentTrafficIntensity &&
            planResults.data?.response?.response[currentTrafficIntensity]
        ) {
            setSummary(
                getDurationSum(
                    planResults.data.response.response[
                        currentTrafficIntensity
                    ] as PlanResponse
                )
            );
        }
    }, [currentTrafficIntensity, planResults.data?.response]);

    useEffect(() => {
        if (planResults.isSuccess) {
            setPlanResultId(planResults.data.response?.result_id);
            if (planResults.data?.response?.response[currentTrafficIntensity]) {
                setModelingData(
                    Object.values(
                        planResults.data?.response?.response[
                            currentTrafficIntensity
                        ]
                    )
                );
            }
        }
    }, [currentTrafficIntensity, planResults]);

    useEffect(() => {
        if (results?.data) {
            const lanes = results?.data.lanes.map((item: LaneResponse) => ({
                lanesId: item.id,
                laneGroupId: item.laneGroup,
            }));
            const laneGroups = results?.data.laneGroups.map(
                (item: LaneGroupResponse) => ({
                    id: item.id,
                    numLanes: item.numLanes,
                    typeTurn: item.typeTurn,
                })
            );
            const lanesArray: LaneResult[] = [];
            lanes.forEach((laneItem: Lane) => {
                laneGroups.find((groupItem: LaneGroup) => {
                    if (groupItem.id === laneItem.laneGroupId) {
                        const testObj = {
                            laneId: laneItem.lanesId,
                            laneGroupId: laneItem.laneGroupId,
                            numLanes: groupItem.numLanes,
                            typeTurn: groupItem.typeTurn,
                        };
                        lanesArray.push(testObj);
                        return true;
                    }
                    return false;
                });
            });
            setAllLanes(lanesArray);
        }
    }, [connectors, results?.data]);

    useEffect(() => {
        if (currentIdTrafficLight && allLanes) {
            const laneGroupsIds: number[] = [];
            const foundedTrafficLight = trafficLightsWithOptions.find(
                (item: TrafficLightWithOptions) =>
                    item.id === currentIdTrafficLight
            );
            if (!foundedTrafficLight) {
                results?.data?.nodes
                    ?.find((item: any) => item.id === currentIdTrafficLight)
                    ?.laneGroupConnectors.forEach((soloItem: Connector) => {
                        if (laneGroupsIds.indexOf(soloItem.from) === -1) {
                            laneGroupsIds.push(soloItem.from);
                        }
                        if (laneGroupsIds.indexOf(soloItem.to) === -1) {
                            laneGroupsIds.push(soloItem.to);
                        }
                    });

                const lanesArray = laneGroupsIds.map((item: number) =>
                    allLanes.filter(
                        (soloItem: LaneResult) => item === soloItem.laneGroupId
                    )
                );

                setTrafficLightsWithOptions([
                    ...trafficLightsWithOptions,
                    {
                        id: currentIdTrafficLight,
                        laneGroups: laneGroupsIds,
                        lanes: lanesArray.flat(),
                    },
                ]);
            }
        }
    }, [
        allLanes,
        currentIdTrafficLight,
        results?.data?.nodes,
        trafficLightsWithOptions,
    ]);

    useEffect(() => {
        if (currentIdTrafficLight && trafficLightsWithOptions) {
            setConnectors(
                trafficLightsWithOptions.find(
                    (item: TrafficLightWithOptions) =>
                        item.id === currentIdTrafficLight
                )?.lanes ?? []
            );
        }
    }, [currentIdTrafficLight, trafficLightsWithOptions]);

    useEffect(() => {
        if (results.isSuccess && results.status === 'fulfilled') {
            setNodes(results?.data?.nodes);
            if (results?.data?.laneFlows.length === 0 && !saveDataId) {
                const flowsBuffer = lanesFlows.map((flowItem) =>
                    flowItem.map((flow: LaneFlowResponse) => ({ ...flow }))
                );
                results?.data?.lanes.forEach((connector: LaneFlowResponse) => {
                    const receivedIntensity = hours.map((hourItem: number) =>
                        getIntensity(hourItem, connector.id)
                    );
                    flowsBuffer.push(receivedIntensity);
                });
                setLaneFlows([flowsBuffer.flat()]);
            } else {
                setLaneFlows([results?.data?.laneFlows]);
            }
        }
        if (
            saveDataId &&
            currentPlanResult.data?.response?.input_data?.laneFlows
        ) {
            setLaneFlows([
                currentPlanResult.data.response.input_data.laneFlows,
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results]);

    useEffect(() => {
        if (mapResults.isSuccess && mapImageRef.current) {
            const objectURL = URL.createObjectURL(mapResults.data);
            mapImageRef.current.src = objectURL;
        }
    }, [mapResults]);

    const columns = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            id: 'number',
            sorter: false,
            width: 50,
            render: (text: string, _: any, index: number) => (
                <span>{index + 1}</span>
            ),
        },
        {
            title: 'Полоса',
            dataIndex: 'laneId',
            key: 'laneId',
            id: 'laneId',
            sorter: false,
        },
        {
            title: 'Группа полос',
            dataIndex: 'laneGroupId',
            key: 'laneGroupId',
            id: 'laneGroupId',
            minWidth: 85,
            sorter: false,
        },
        {
            title: 'Количество полос',
            dataIndex: 'numLanes',
            key: 'numLanes',
            id: 'numLanes',
            minWidth: 120,
            sorter: false,
        },
        {
            title: 'Тип полосы',
            dataIndex: 'typeTurn',
            key: 'typeTurn',
            id: 'typeTurn',
            minWidth: 120,
            sorter: false,
            render: (text: 'DEFAULT' | 'ALLOCATED') => (
                <div className={styles.eventCell}>{typesTurn[text]}</div>
            ),
        },
        {
            title: '',
            dataIndex: 'button',
            key: 'button',
            id: 'button',
            sorter: false,
            render: (text: string, record: LaneResult) => (
                <div className={styles.eventCell}>
                    <Button
                        variant="dark"
                        onClick={() => {
                            if (record.laneId) {
                                handleShow(record.laneId);
                            }
                        }}
                        className={styles.tableShowButton}
                    >
                        И
                    </Button>
                </div>
            ),
        },
    ];
    const resultsColumns = [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            id: 'number',
            sorter: false,
            render: (text: string, _: any, index: number) => (
                <span>{index + 1}</span>
            ),
        },
        {
            title: 'Продолжительность',
            dataIndex: 'duration',
            key: 'duration',
            id: 'duration',
            sorter: false,
        },
        {
            title: 'Фазовый коэффициент',
            dataIndex: 'y',
            key: 'y',
            id: 'y',
            sorter: false,
        },
        {
            title: 'Тип фазы',
            dataIndex: 'typePhase',
            key: 'typePhase',
            id: 'typePhase',
            sorter: false,
            render: (text: 'VEHICLE' | 'CLEARING' | 'PEDESTRIAN') => (
                <div className={styles.eventCell}>{typesPhase[text]}</div>
            ),
        },
    ];

    return {
        amountProgramsValue,
        amountProgramsChecked,
        columns,
        connectors,
        currentFlows,
        currentIdTrafficLight,
        currentPlanResult,
        currentTrafficIntensity,
        handleChangeAmountProgramsChecked,
        handleChangeAmountProgramsValue,
        handleChangeCloseModal,
        handleChangeCurrentFlows,
        handleChangeSliderValue,
        handleChangeSmoothingValue,
        handleClickTrafficLights,
        handleChangeTrafficIntensity,
        handleSave,
        handleShowResults,
        handleReset,
        mapImageRef,
        mapResults,
        modelingData,
        nodes,
        planResults,
        planResultsData,
        results,
        resultsColumns,
        savePlanResults,
        saveDataId,
        sliderValue,
        showModal,
        showResults,
        smoothingValue,
        summary,
        trafficLightsWithOptions,
    };
};

export default useCoordinatedControlResultsPage;
