import { emptySplitApi } from '../../../../api/api';

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getPlan: build.mutation({
            query: (params) => ({
                url: `${process.env.REACT_APP_BACKEND_URL}api/get_plan/`,
                method: 'POST',
                body: {
                    zone_id: params.zone_id,
                    flag: params.flag,
                    statType: Number(params.statType),
                    workDays: Boolean(params.workDays),
                    holyDays: Boolean(params.holyDays),
                    smoothing: params.smoothing,
                    amount_proggramms: params.amount_proggramms,
                    period_time: params.period_time,
                    laneFlows: params.flows,
                },
            }),
        }),
        savePlan: build.mutation({
            query: (params) => ({
                url: `${process.env.REACT_APP_BACKEND_URL}api/save_result/`,
                method: 'POST',
                body: {
                    result_id: params.result_id,
                },
            }),
        }),
        getMap: build.query({
            query: (params) => ({
                url: `${process.env.REACT_APP_GET_CONNECTORS_IMAGE}/${params.id}`,
                method: 'GET',
                headers: {
                    'content-type': 'image/png;charset=UTF-8',
                },
                params: {
                    groups: true,
                },
                responseHandler: (response) => response.blob(),
            }),
        }),
        getConnectors: build.query({
            query: (params) => ({
                url: `${process.env.REACT_APP_GET_ZONE}/${params.id}`,
                method: 'GET',
                params: {
                    statType: params.statType,
                    workDays: params.workDays,
                    holyDays: params.holyDays,
                },
            }),
        }),
        getCurrentPlan: build.query({
            query: (params) => ({
                url: `${process.env.REACT_APP_BACKEND_URL}api/get_result/`,
                method: 'GET',
                params: {
                    result_id: params.result_id,
                },
            }),
        }),
        getTrafficLightsZone: build.query({
            query: (params) => ({
                url: `${process.env.REACT_APP_GET_DEVICE}`,
                method: 'POST',
                body: {
                    andConditions: [
                        {
                            path: 'axesNodes.zone.id',
                            condition: 1,
                            value: params.id,
                        },
                        { path: 'deviceType.id', condition: 1, value: 1 },
                    ],
                },
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetPlanMutation,
    useSavePlanMutation,
    useLazyGetMapQuery,
    useLazyGetConnectorsQuery,
    useLazyGetCurrentPlanQuery,
    useLazyGetTrafficLightsZoneQuery,
} = extendedApi;
