import { Button } from 'react-bootstrap';

import styles from './trafficLightsBlock.module.css';

type TrafficLightsBlockProps = {
    onClick: (id: number) => void;
    dataSource?: any;
    active?: number | null;
};

function TrafficLightsBlock({
    onClick,
    dataSource,
    active,
}: TrafficLightsBlockProps) {
    return (
        <div className={styles.trafficIntensityWrapper}>
            <div className={styles.title}>Светофорные объекты</div>
            <div className={styles.timesRadioWrapper}>
                {dataSource?.map((item: any) => (
                    <Button
                        key={`TrafficLightsBlock${item.id}`}
                        variant={active === item.id ? 'success' : 'dark'}
                        className={styles.button}
                        onClick={() => onClick(item.id)}
                    >
                        {item.id}
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default TrafficLightsBlock;
