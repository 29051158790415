import AuthPage from 'components/pages/authPage/UI/authPage';

import useAuthWrapper from './logic/useAuthWrapper';

type AuthorizationPageProps = {
    children: JSX.Element | null;
};

function AuthorizationWrapper({ children }: AuthorizationPageProps) {
    const { isAuth } = useAuthWrapper();
    if (isAuth) {
        return children;
    }
    return <AuthPage />;
}

export default AuthorizationWrapper;
