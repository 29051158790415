import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClassName } from 'utils/helpers';

import styles from './menuItem.module.css';

type MenuItemProps = {
    text?: string;
    active?: boolean;
    className?: string;
    onClick?: () => void;
    isVisibleArrow?: boolean;
    isOpen?: boolean;
    iconType?: any;
    isDefault?: boolean;
};

export function MenuItem({
    text,
    active = false,
    className = '',
    onClick,
    iconType,
    isVisibleArrow = false,
    isOpen = false,
    isDefault = false,
}: MenuItemProps) {
    return (
        <li
            tabIndex={0}
            role="menuitem"
            onKeyPress={onClick}
            onClick={onClick}
            className={
                active
                    ? getClassName(styles.wrapper, styles.active, className)
                    : getClassName(styles.wrapper, className)
            }
        >
            {!isDefault ? (
                <FontAwesomeIcon className={styles.icon} icon={iconType} />
            ) : (
                <svg
                    className={styles.homeIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                >
                    <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" />
                </svg>
            )}

            <div className={styles.text}>{text}</div>
            {isVisibleArrow && (
                <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    className={getClassName(
                        isOpen ? styles.open : styles.close,
                        styles.arrow
                    )}
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            )}
        </li>
    );
}
