import { configureStore } from '@reduxjs/toolkit';
import { emptySplitApi } from 'api/api';

import currentMapZone from '../components/molecules/map/reducers/zone';
import authorization from '../components/organisms/authWrapper/reducers/authorization';
import burgerMenu from '../components/organisms/burgerMenu/reducers/isOpenBurgerMenu';

export const store = configureStore({
    reducer: {
        authorization,
        burgerMenu,
        currentMapZone,
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            emptySplitApi.middleware
        ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
