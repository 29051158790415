import './buttonhint.css';

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

type ButtonHintProps = {
    onClick?: () => void;
    visible: boolean;
};

function ButtonHint({ onClick, visible }: ButtonHintProps) {
    const [visibleHint, setVisibleHint] = useState(false);

    useEffect(() => {
        if (!visible) {
            setVisibleHint(false);
        }
    }, [visible]);

    return (
        <div className="map-hint-control">
            {visible && (
                <div className="map-hint-btn-wrapper">
                    <Button
                        size="sm"
                        variant="light"
                        className="map-hint-btn"
                        onMouseEnter={() => setVisibleHint(true)}
                        onMouseLeave={() => setVisibleHint(false)}
                        onClick={onClick}
                    >
                        Сброс
                    </Button>
                </div>
            )}

            {visibleHint && <div className="map-hint">Отобразить все ОТ</div>}
        </div>
    );
}

export default ButtonHint;
