import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { LaneResult } from 'utils/types';

import styles from './connectorsTable.module.css';

type ConnectorsTableProps = {
    columns?: ColumnsType<LaneResult>;
    dataSource?: LaneResult[];
    isLoading?: boolean;
};

function ConnectorsTable({
    columns,
    dataSource,
    isLoading,
}: ConnectorsTableProps) {
    return (
        <>
            <div className={styles.title}>Полосы</div>
            <Table
                rowKey="id"
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                className={styles.table}
                loading={isLoading}
                scroll={{ y: 400 }}
            />
        </>
    );
}

export default ConnectorsTable;
