import { notification } from 'antd';
import dayjs from 'dayjs';

import { Phase, PlanResponse } from './types';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(';').shift();
    return '';
}

export const getClassName = (...classes: Array<string>) =>
    classes.join(' ').trim();

export const deleteCookie = (name: string) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const openNotification = (type: NotificationType, text: string) => {
    notification[type]({
        message: text,
        duration: 3,
    });
};

export const checkForNumberValue = (value: string) => {
    if (value === '0') return '0';
    if (!parseInt(value, 10)) {
        return '';
    }
    if (parseInt(value, 10) < 0) {
        return '0';
    }
    return `${parseInt(value, 10)}`;
};

export const getIntensity = (
    index: number,
    currentId: number | string | null
) => ({
    code: null,
    lane: Number(currentId),
    count: 0,
    id: null,
    time: `${dayjs()
        .second(0)
        .hour(0)
        .minute(0)
        .add(index, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss')}`,
});

export const getDurationSum = (planResults: PlanResponse) => {
    let sum = 0;
    const resultsArray: Phase[] = Object.values(planResults);
    resultsArray.forEach((item: Phase) => {
        if (item.duration) {
            sum += Number(item.duration);
        }
    });
    return sum;
};
