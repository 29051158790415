import { useCallback, useEffect, useRef, useState } from 'react';

const useMenu = () => {
    const [visible, setVisible] = useState(false);
    const [fullTitle, setFullTitle] = useState(false);

    const handleChangeVisibleNestedMenu = () => {
        setVisible(!visible);
    };
    const hideNestedMenu = () => {
        setFullTitle(false);
        setVisible(false);
    };

    const ref = useRef<HTMLUListElement | null>(null);

    const handleClickOutside = useCallback(
        (event: any) => {
            if (visible && ref.current && !ref.current.contains(event.target)) {
                setVisible(false);
            }
        },
        [visible]
    );

    useEffect(
        () =>
            // if (visible) {
            //     document.addEventListener('click', handleClickOutside, true);
            // }
            () => {
                document.removeEventListener('click', handleClickOutside, true);
            },
        [handleClickOutside, visible]
    );
    return {
        handleChangeVisibleNestedMenu,
        hideNestedMenu,
        setFullTitle,
        fullTitle,
        visible,
        ref,
    };
};

export default useMenu;
