import {
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import {
    BaseQueryApi,
    BaseQueryFn,
    QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';

interface RefreshResultData {
    data: {
        access_token: string;
        expires_in: number;
        refresh_token: string;
        token_type: string;
    };
}
interface ErrorData {
    data: string;
    error: string;
    originalStatus: number;
    status: string;
}

export const mapInterceptor = async (
    result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
    args: string | FetchArgs,
    baseQuery: BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
    >,
    api: BaseQueryApi,
    extraOptions: {}
) => {
    if (
        result.error &&
        (result.error.status === 401 ||
            (result.error as ErrorData).originalStatus === 401)
    ) {
        const refreshResult = await baseQuery(
            {
                url: `${process.env.REACT_APP_MAP_LINK_FOR_TOKEN}`,
                method: 'GET',
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            localStorage.setItem(
                'map-token',
                (refreshResult as RefreshResultData).data.access_token
            );
            const newData = await baseQuery(args, api, extraOptions);
            return newData;
        }
    }
    return result;
};
