/* eslint no-underscore-dangle: 0 */
import { useLazyGetTrafficLightsZoneQuery } from 'components/pages/localResultsPage/requests/requests';
import { useAppSelector } from 'hooks/defaulthooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { openNotification } from 'utils/helpers';

const useOptionsPage = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const zone = useAppSelector((state) => state.currentMapZone.zone);

    const [workDaysValue, setWorkDaysValue] = useState(true);
    const [holyDaysValue, setHolyDaysValue] = useState(true);

    const handleChangeWorkDaysValue = () => {
        setWorkDaysValue(!workDaysValue);
    };

    const handleChangeHolyDaysValue = () => {
        setHolyDaysValue(!holyDaysValue);
    };

    const [timeLagValue, setTimeLagValue] = useState('');

    const handleChangeTimeLagValue = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setTimeLagValue(e.target.value);
    };

    const [getTrafficLightsZone, resultsZone] =
        useLazyGetTrafficLightsZoneQuery();

    const handleChangePage = () => {
        if (timeLagValue && zone) {
            const id = String(zone?.id).split('.')[1];
            getTrafficLightsZone({
                id,
            });
        } else {
            openNotification('error', 'Необходимо выбрать все данные');
        }
    };

    useEffect(() => {
        if (resultsZone.isSuccess && resultsZone.status === 'fulfilled') {
            if (
                resultsZone.data._embedded &&
                resultsZone.data._embedded.hardwareDevices.length > 1 &&
                pathname === '/mesoscopic-modeling/coordinated-control'
            ) {
                const id = String(zone?.id).split('.')[1];
                navigate(
                    `/mesoscopic-modeling/coordinated-control/${id}?lag=${timeLagValue}&workDays=${workDaysValue}&holyDays=${holyDaysValue}`
                );
            } else if (
                pathname === '/mesoscopic-modeling/coordinated-control'
            ) {
                openNotification(
                    'error',
                    'Объект находится на локальном управлении. Координированное управление невозможно'
                );
            }
            if (
                resultsZone.data._embedded &&
                resultsZone.data._embedded.hardwareDevices.length < 2 &&
                pathname === '/mesoscopic-modeling/local-calculations'
            ) {
                const id = String(zone?.id).split('.')[1];
                navigate(
                    `/mesoscopic-modeling/local-calculations/${id}?lag=${timeLagValue}&workDays=${workDaysValue}&holyDays=${holyDaysValue}`
                );
            } else if (pathname === '/mesoscopic-modeling/local-calculations') {
                openNotification(
                    'error',
                    'Объект находится в группе координированного управления. Локальное управление невозможно'
                );
            }
        }
    }, [resultsZone]);

    return {
        handleChangeHolyDaysValue,
        handleChangeTimeLagValue,
        handleChangePage,
        handleChangeWorkDaysValue,
        holyDaysValue,
        workDaysValue,
    };
};

export default useOptionsPage;
