import { emptySplitApi } from '../../../../api/api';

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getLogging: build.query({
            query: (params) => ({
                url: `${process.env.REACT_APP_BACKEND_URL}api/get_logs/`,
                method: 'GET',
                params: {
                    size: params.size,
                    page: params.page,
                },
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useLazyGetLoggingQuery } = extendedApi;
