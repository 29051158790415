import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useGetTokenMapMutation } from '../requests/requests';

const useUpdateToken = (onUpdateToken: Function | null) => {
    const dispatch = useDispatch();

    const [tokenMap, setTokenMap] = useState<string>('');
    const [getToken, result] = useGetTokenMapMutation({});

    const getTokenForMap = useCallback(() => {
        getToken({});
    }, [getToken]);

    useEffect(() => {
        const token = localStorage.getItem('map-token');
        if (!token) {
            getTokenForMap();
        } else {
            setTokenMap(token);
        }
    }, [dispatch, getTokenForMap]);

    useEffect(() => {
        if (result.status === 'fulfilled') {
            localStorage.setItem('map-token', result.data.access_token);
            setTokenMap(result.data.access_token);
            if (onUpdateToken) {
                onUpdateToken();
            }
        }
    }, [result.status, dispatch, result.data?.access_token, onUpdateToken]);

    return { tokenMap, getTokenForMap };
};

export default useUpdateToken;
