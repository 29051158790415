import Pathname from 'components/atoms/Pathname/UI/Pathname';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getClassName } from 'utils/helpers';

import useHeader from '../logic/useHeader';
import styles from './header.module.css';

type HeaderProps = {
    name?: string;
};

export default function Header({ name = '' }: HeaderProps) {
    const {
        pathname,
        handleLogout,
        isOpen,
        open,
        handleChangeVisibleBurgerMenuClose,
        handleChangeVisibleBurgerMenuOpen,
    } = useHeader();

    return (
        <header className={styles.header}>
            <Link
                to="/"
                className={getClassName(styles.mainTitle, 'unselectable')}
            >
                Модуль транспортного прогнозирования и моделирования
            </Link>
            <Pathname pathname={pathname} />
            <Dropdown className={styles.authMenu}>
                <Dropdown.Toggle id="dropdown-basic" variant="light">
                    {name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item variant="light" onClick={handleLogout}>
                        Выйти
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                className={styles.svg}
                onClick={
                    open
                        ? handleChangeVisibleBurgerMenuClose
                        : handleChangeVisibleBurgerMenuOpen
                }
            >
                {isOpen ? (
                    <path
                        x-show="open"
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                ) : (
                    <path
                        x-show="!open"
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                )}
            </svg>
        </header>
    );
}
