import GISMap from 'components/molecules/map/UI/map';

import styles from './homePage.module.css';

function HomePage() {
    return (
        <div className={styles.mainWrapper}>
            <GISMap />
        </div>
    );
}

export default HomePage;
