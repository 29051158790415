import { setIsAuthorization } from 'components/organisms/authWrapper/reducers/authorization';
import { useLazyGetLogoutQuery } from 'components/organisms/Header/requests/requests';
import { useAppDispatch, useAppSelector } from 'hooks/defaulthooks';
import { useCallback, useEffect, useRef, useState } from 'react';

import { setIsOpen } from '../reducers/isOpenBurgerMenu';

const useMenu = () => {
    const isOpen = useAppSelector((state) => state.burgerMenu.isOpen);
    const dispatch = useAppDispatch();
    const [visible, setVisible] = useState(false);
    const [visibleAuthNestedMenu, setVisibleAuthNestedMenu] = useState(false);
    const [logout, params] = useLazyGetLogoutQuery();

    const handleChangeVisibleNestedMenu = () => {
        if (visibleAuthNestedMenu) {
            setVisibleAuthNestedMenu(!visibleAuthNestedMenu);
        }
        setVisible(!visible);
    };

    const handleChangeVisibleAuthNestedMenu = () => {
        if (visible) {
            setVisible(!visible);
        }
        setVisibleAuthNestedMenu(!visibleAuthNestedMenu);
    };

    const handleCloseBurgerMenu = useCallback(() => {
        dispatch(setIsOpen(false));
        setVisibleAuthNestedMenu(false);
        setVisible(false);
    }, [dispatch]);

    const ref = useRef<HTMLUListElement | null>(null);

    const handleClickOutside = useCallback(
        (event: any) => {
            if (isOpen && ref.current && !ref.current.contains(event.target)) {
                handleCloseBurgerMenu();
            }
        },
        [isOpen, handleCloseBurgerMenu]
    );

    const handleLogout = async () => {
        await logout({});
    };

    useEffect(() => {
        if (params.isSuccess) {
            dispatch(setIsAuthorization({ isAuth: false }));
            localStorage.clear();
            handleCloseBurgerMenu();
        }
    }, [dispatch, handleCloseBurgerMenu, params]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isOpen, handleClickOutside]);
    return {
        handleChangeVisibleAuthNestedMenu,
        handleChangeVisibleNestedMenu,
        handleCloseBurgerMenu,
        visible,
        visibleAuthNestedMenu,
        ref,
        isOpen,
        handleLogout,
    };
};

export default useMenu;
