import { Overlay } from 'ol';
import ImageLayer from 'ol/layer/Image.js';
import Map from 'ol/Map';
import ImageWMS from 'ol/source/ImageWMS';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setZone } from '../reducers/zone';

interface UseLayersProps {
    mapValue?: Map | null;
    isChecking?: boolean;
    tokenMap: string;
    isResetLayer?: boolean;
    getTokenForMap: () => void;
}

const useLayers = ({
    mapValue,
    isChecking,
    tokenMap,
    getTokenForMap,
}: UseLayersProps) => {
    const dispatch = useDispatch();
    const OverlayRef = useRef<HTMLDivElement | null>(null);
    const MapPopupContentRef = useRef<HTMLDivElement | null>(null);

    const [overlay, setOverlay] = useState<Overlay>();
    const [isVisibleResetBtn, setIsVisibleResetBtn] = useState(false);
    const [forecastIntervalId, setForecastIntervalId] = useState<any>(null);
    const [isTokenUpdate, setIsTokenUpdate] = useState(false);
    const [tokenShouldUpdate, setTokenShouldUpdate] = useState(false);

    const apiRequest = useCallback(
        (
            urlApi: string | undefined,
            callback: (progressEvent: any) => void,
            responseType:
                | 'arraybuffer'
                | 'blob'
                | 'document'
                | 'json'
                | 'text' = 'arraybuffer'
        ) => {
            if (urlApi && tokenMap) {
                const client = new XMLHttpRequest();
                client.open('GET', urlApi);
                client.responseType = responseType;
                client.setRequestHeader('Authorization', `Bearer ${tokenMap}`);
                client.onload = callback;
                client.send();
            }
        },
        [tokenMap]
    );

    useEffect(() => {
        setIsTokenUpdate(true);
        if (tokenShouldUpdate && !isTokenUpdate) {
            getTokenForMap();
            setTokenShouldUpdate(false);
        }
    }, [getTokenForMap, isTokenUpdate, tokenShouldUpdate]);

    const imageLoadFunction = useCallback(
        (image: any, src: string) => {
            apiRequest(src, (progressEvent) => {
                if (
                    progressEvent.currentTarget.status === 401 &&
                    !isTokenUpdate
                ) {
                    setTokenShouldUpdate(true);
                } else {
                    const { response } = progressEvent.currentTarget;
                    const arrayBufferView = new Uint8Array(response);
                    const blob = new Blob([arrayBufferView], {
                        type: 'image/png',
                    });
                    const urlCreator = window.URL || window.webkitURL;
                    // eslint-disable-next-line no-param-reassign
                    image.getImage().src = urlCreator.createObjectURL(blob);
                }
            });
        },
        [apiRequest]
    );

    const createOverlay = () => {
        if (OverlayRef.current) {
            return new Overlay({
                element: OverlayRef.current,
                autoPan: {
                    animation: {
                        duration: 250,
                    },
                },
            });
        }
        return undefined;
    };

    const createLayer = useCallback(
        () =>
            new ImageLayer({
                source: new ImageWMS({
                    url: `${process.env.REACT_APP_MAP_LINK}/maps/1/its/wms`,
                    params: {
                        LAYERS: ['its:MIR'],
                        TILED: false,
                        FORMAT: 'image/png8',
                        VERSION: '1.1.1',
                    },
                    imageLoadFunction,
                    serverType: 'geoserver',
                    crossOrigin: 'anonymous',
                }),
            }),
        [imageLoadFunction]
    );

    const createZoneLayer = () =>
        new ImageLayer({
            source: new ImageWMS({
                url: `${process.env.REACT_APP_MAP_LINK}/maps/3/its/wms`,
                params: {
                    LAYERS: ['its:zone'],
                    TILED: false,
                    FORMAT: 'image/png8',
                    VERSION: '1.1.1',
                },
                imageLoadFunction,
                serverType: 'geoserver',
                crossOrigin: 'anonymous',
            }),
        });

    const zoneLayer = createZoneLayer();

    const createDeviceLayer = useCallback(
        () =>
            new ImageLayer({
                source: new ImageWMS({
                    url: `${process.env.REACT_APP_MAP_LINK}/maps/13/its/wms`,
                    params: {
                        LAYERS: ['its:device'],
                        TILED: false,
                        FORMAT: 'image/png8',
                        VERSION: '1.1.1',
                    },
                    imageLoadFunction,
                    serverType: 'geoserver',
                    crossOrigin: 'anonymous',
                }),
            }),
        [imageLoadFunction]
    );

    const deviceLayer = createDeviceLayer();

    const createPublicTransportLayer = () =>
        new ImageLayer({
            source: new ImageWMS({
                url: `${process.env.REACT_APP_MAP_LINK}/maps/13/its/wms`,
                params: {
                    LAYERS: ['its:public_transport'],
                    TILED: false,
                    FORMAT: 'image/png8',
                    VERSION: '1.1.1',
                },
                imageLoadFunction,
                serverType: 'geoserver',
                crossOrigin: 'anonymous',
            }),
        });

    const createForecastTransportLayer = useCallback(
        (id: number | string) =>
            new ImageLayer({
                source: new ImageWMS({
                    url: `${process.env.REACT_APP_MAP_LINK}/maps/13/its/wms`,
                    params: {
                        LAYERS: ['its:transport_forecast'],
                        TILED: false,
                        FORMAT: 'image/png8',
                        VERSION: '1.1.1',
                        VIEWPARAMS: `controller:${id}`,
                    },
                    imageLoadFunction,
                    serverType: 'geoserver',
                    crossOrigin: 'anonymous',
                }),
            }),
        [imageLoadFunction]
    );

    const removeLayers = () => {
        if (mapValue && overlay && mapValue.getLayers().getArray().length > 0) {
            mapValue
                .getLayers()
                .getArray()
                .forEach((soloLayer) => {
                    mapValue.removeLayer(soloLayer);
                });
        }
    };

    const layer = createLayer();

    const removeTransportLayer = () => {
        if (mapValue && overlay && mapValue.getLayers().getArray().length > 0) {
            mapValue
                .getLayers()
                .getArray()
                .forEach((soloLayer) => {
                    const layerName =
                        // eslint-disable-next-line no-underscore-dangle
                        soloLayer.getProperties().source.params_.LAYERS[0];
                    if (layerName === 'its:transport_forecast') {
                        mapValue.removeLayer(soloLayer);
                    }
                });
        }
    };

    const removePublicTransportLayer = () => {
        if (mapValue && overlay && mapValue.getLayers().getArray().length > 0) {
            mapValue
                .getLayers()
                .getArray()
                .forEach((soloLayer) => {
                    const layerName =
                        // eslint-disable-next-line no-underscore-dangle
                        soloLayer.getProperties().source.params_.LAYERS[0];
                    if (layerName === 'its:public_transport') {
                        mapValue.removeLayer(soloLayer);
                    }
                });
        }
    };

    const refreshTransportLayer = (id: number | string) => {
        const forecastTransportLayer = createForecastTransportLayer(id);

        if (mapValue && overlay) {
            removeTransportLayer();
            mapValue.addLayer(forecastTransportLayer);
        }
    };

    let intervals: any[] = [];
    let publicInterval: any = null;

    const refreshPublicTransportLayer = (interval?: any) => {
        const publicTransportLayer = createPublicTransportLayer();
        if (mapValue && overlay) {
            mapValue
                .getLayers()
                .getArray()
                .forEach((soloLayer) => {
                    const layerName =
                        // eslint-disable-next-line no-underscore-dangle
                        soloLayer.getProperties().source.params_.LAYERS[0];
                    if (layerName === 'its:transport_forecast') {
                        clearInterval(publicInterval);
                        clearInterval(interval);
                        removePublicTransportLayer();
                        console.log(publicInterval);
                    } else {
                        removePublicTransportLayer();
                        mapValue.addLayer(publicTransportLayer);
                        publicInterval = interval;
                    }
                });
        }
    };

    const handlerBtnClick = () => {
        setIsVisibleResetBtn(false);

        const publicTransportLayer = createPublicTransportLayer();
        if (mapValue && forecastIntervalId) {
            clearInterval(forecastIntervalId);
            removeTransportLayer();
            mapValue.addLayer(publicTransportLayer);
            setForecastIntervalId(null);
            const interval = setInterval(() => {
                refreshPublicTransportLayer(interval);
            }, 15000);
        }
    };

    const handlerClick = (event: { map?: any; coordinate?: any }) => {
        const { coordinate } = event;
        let urlLocal;
        let urlDevices;
        try {
            urlLocal = zoneLayer
                ?.getSource()
                ?.getFeatureInfoUrl(
                    coordinate,
                    event.map.getView().getResolution(),
                    'EPSG:3857',
                    {
                        INFO_FORMAT: 'application/json',
                        FEATURE_COUNT: 50,
                    }
                );

            urlDevices = deviceLayer
                ?.getSource()
                ?.getFeatureInfoUrl(
                    coordinate,
                    event.map.getView().getResolution(),
                    'EPSG:3857',
                    {
                        INFO_FORMAT: 'application/json',
                        FEATURE_COUNT: 50,
                    }
                );
        } catch (ex) {
            return;
        }
        apiRequest(
            urlDevices,
            (progressEvent) => {
                const { response } = progressEvent.currentTarget;
                if (
                    typeof response?.features !== 'undefined' &&
                    response?.features?.length > 0 &&
                    !isChecking
                ) {
                    const deviceId = response.features[0].id.split('.')[1];

                    if (mapValue && overlay) {
                        const forecastTransportLayer =
                            createForecastTransportLayer(deviceId);

                        setIsVisibleResetBtn(true);

                        removeTransportLayer();
                        removePublicTransportLayer();
                        mapValue.addLayer(forecastTransportLayer);

                        const interval = setInterval(() => {
                            refreshTransportLayer(deviceId);
                        }, 15000);

                        setForecastIntervalId(interval);

                        if (!intervals.includes(interval)) {
                            intervals.push(interval);
                        }
                        if (intervals.length > 1) {
                            clearInterval(intervals[0]);
                            intervals = intervals.filter(
                                (e) => e !== intervals[0]
                            );
                        }
                    }
                }
            },
            'json'
        );
        apiRequest(
            urlLocal,
            (progressEvent) => {
                const { response } = progressEvent.currentTarget;
                if (
                    typeof response?.features !== 'undefined' &&
                    response?.features?.length > 0
                ) {
                    let nameZone = null;
                    response?.features?.some((item: any) => {
                        switch (item.id.split('.')[0]) {
                            case 'zone':
                                nameZone = item.properties.name;
                                if (isChecking) {
                                    dispatch(setZone(item));
                                }
                                return true;
                            default:
                                return false;
                        }
                    });
                    if (nameZone === null) {
                        return;
                    }
                    if (MapPopupContentRef.current) {
                        MapPopupContentRef.current.innerHTML = `<p>Зона: ${nameZone}</p>`;
                    }
                }
            },
            'json'
        );
    };

    useEffect(() => {
        setOverlay(createOverlay());
    }, []);

    const createLayers = () => {
        const publicTransportLayer = createPublicTransportLayer();
        if (mapValue && overlay) {
            mapValue.addLayer(layer);
            mapValue.addOverlay(overlay);
            mapValue.addLayer(zoneLayer);
            mapValue.addLayer(deviceLayer);
            if (!isChecking) {
                mapValue.addLayer(publicTransportLayer);
                const interval = setInterval(() => {
                    refreshPublicTransportLayer(interval);
                }, 15000);
            }
        }
    };

    useEffect(() => {
        if (mapValue && overlay && zoneLayer) {
            removeLayers();
            createLayers();
            mapValue?.un('singleclick', handlerClick);
            mapValue?.on('singleclick', handlerClick);
        }
    }, [tokenMap]);

    return {
        overlay,
        OverlayRef,
        MapPopupContentRef,
        tokenMap,
        isVisibleResetBtn,
        handlerBtnClick,
        onUpdateToken: () => {
            setIsTokenUpdate(false);
        },
    };
};

export default useLayers;
