import { emptySplitApi } from '../../../../api/api';

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getToken: build.mutation({
            query: (code) => ({
                url: `${process.env.REACT_APP_TOKEN_LINK}`,
                method: 'POST',
                body: {
                    grant_type: 'authorization_code',
                    client_id: process.env.REACT_APP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_SECRET_KEY,
                    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
                    code,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetTokenMutation } = extendedApi;
