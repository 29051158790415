import styles from './pathname.module.css';

const pathnames = [
    {
        id: 1,
        link: '/logging',
        title: ['Журнал логирования'],
    },
    {
        id: 2,
        link: '/mesoscopic-modeling/modeling-results',
        title: ['Мезоскопическое моделирование', 'Результаты моделирования'],
    },
    {
        id: 3,
        link: '/mesoscopic-modeling/local-calculations',
        title: ['Мезоскопическое моделирование', 'Локальное'],
    },
    {
        id: 4,
        link: '/mesoscopic-modeling/coordinated-control',
        title: ['Мезоскопическое моделирование', 'Координированное'],
    },
    {
        id: 5,
        link: '/mesoscopic-modeling/local-calculations',
        title: ['Мезоскопическое моделирование', 'Результаты моделирования'],
    },
];

type PathnameProps = {
    pathname?: string;
};

function Pathname({ pathname = '' }: PathnameProps) {
    let newPathname = pathname;
    if (newPathname.split('/').length === 4) {
        newPathname = newPathname.split('/').slice(0, 3).join('/');
    }
    const foundPathname = pathnames.find((el) => el.link === newPathname);
    const element = foundPathname?.title.map((item, index) => (
        <div key={`header${item}`} className={styles.title}>
            {index !== 0 && <>&nbsp;/&nbsp;</>}
            {item}
        </div>
    ));

    if (element) return <div className={styles.titleWrapper}>{element}</div>;
    return (
        <div className={styles.titleWrapper}>
            <div className={styles.title}>
                Прогноз прибытия общественного транспорта
            </div>
        </div>
    );
}

export default Pathname;
