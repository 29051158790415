import Button from 'react-bootstrap/Button';

import styles from './buttonAuthWrapper.module.css';

type ButtonAuthWrapperProps = {
    onClick?: () => void;
};

function ButtonAuthWrapper({ onClick }: ButtonAuthWrapperProps) {
    return (
        <div className={styles.buttonWrapper}>
            <Button variant="dark" onClick={onClick}>
                Авторизация
            </Button>
        </div>
    );
}

export default ButtonAuthWrapper;
