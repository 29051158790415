import {
    faCalculator,
    faCrosshairs,
    faListUl,
    faProjectDiagram,
    faSquarePollHorizontal,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getClassName } from 'utils/helpers';

import { MenuItem } from '../../../molecules/menuItem/UI/menuItem';
import useMenu from '../logic/useMenu';
import styles from './menu.module.css';

export default function Menu() {
    const {
        handleChangeVisibleNestedMenu,
        hideNestedMenu,
        setFullTitle,
        fullTitle,
        visible,
        ref,
    } = useMenu();

    const homeLink = String(process.env.REACT_APP_AUTH_LINK);

    const showModelingResult = !!JSON.parse(
        localStorage.getItem('permissions') || '[]'
    ).find((word: string) => word.indexOf('show') !== -1);

    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <nav
            className={styles.menu}
            onMouseLeave={hideNestedMenu}
            onMouseOver={() => setFullTitle(true)}
        >
            <Link
                to="/"
                className={getClassName(
                    styles.title,
                    'unselectable',
                    fullTitle ? styles.title__full : ''
                )}
            >
                {fullTitle
                    ? 'Модуль транспортного прогнозирования и моделирования'
                    : 'МТПМ'}
            </Link>
            <ul className={styles.menuBlock}>
                <MenuItem
                    className="unselectable"
                    text="Мезоскопическое моделирование"
                    iconType={faCalculator}
                    isVisibleArrow
                    isOpen={visible}
                    onClick={handleChangeVisibleNestedMenu}
                />
                <ul
                    className={getClassName(
                        styles.nestedBlock,
                        visible ? styles.visible : styles.hide,
                        'unselectable'
                    )}
                    ref={ref}
                >
                    <Link
                        to="/mesoscopic-modeling/local-calculations"
                        className={styles.link}
                    >
                        <MenuItem
                            text="Расчет локальной программы"
                            iconType={faCrosshairs}
                            className={getClassName(
                                styles.nestedItem,
                                'unselectable'
                            )}
                            onClick={handleChangeVisibleNestedMenu}
                        />
                    </Link>
                    <Link
                        to="/mesoscopic-modeling/coordinated-control"
                        className={styles.link}
                    >
                        <MenuItem
                            text="Расчет программы координированного управления"
                            iconType={faProjectDiagram}
                            className={getClassName(
                                styles.nestedItem,
                                'unselectable'
                            )}
                            onClick={handleChangeVisibleNestedMenu}
                        />
                    </Link>
                    {showModelingResult && (
                        <Link
                            to="/mesoscopic-modeling/modeling-results"
                            className={styles.link}
                        >
                            <MenuItem
                                text="Результаты моделирования"
                                iconType={faSquarePollHorizontal}
                                className={getClassName(
                                    styles.nestedItem,
                                    'unselectable'
                                )}
                                onClick={handleChangeVisibleNestedMenu}
                            />
                        </Link>
                    )}
                </ul>
                <Link to="/logging" className={styles.link}>
                    <MenuItem
                        className="unselectable"
                        iconType={faListUl}
                        text="Журнал логирования"
                    />
                </Link>
                <a href={homeLink} className={styles.link}>
                    <MenuItem
                        className="unselectable"
                        isDefault
                        text="Стартовая страница"
                    />
                </a>
            </ul>
        </nav>
    );
}
