import CoordinatedControlResultsPage from 'components/pages/coordinatedControlResultsPage/UI/coordinatedControlResultsPage';
import HomePage from 'components/pages/homePage/UI/homePage';
import LocalResultsPage from 'components/pages/localResultsPage/UI/localResultsPage';
import LoggingPage from 'components/pages/loggingPage/UI/loggingPage';
import ModelingResultsPage from 'components/pages/ModelingResultsPage/UI/ModelingResultsPage';
import OptionsPage from 'components/pages/OptionsPage/UI/OptionsPage';
import { Route, Routes } from 'react-router-dom';

export function RoutesPaths() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
                path="/mesoscopic-modeling/local-calculations"
                element={<OptionsPage />}
            />
            <Route
                path="/mesoscopic-modeling/local-calculations/:id"
                element={<LocalResultsPage />}
            />
            <Route
                path="/mesoscopic-modeling/coordinated-control"
                element={<OptionsPage />}
            />
            <Route
                path="/mesoscopic-modeling/coordinated-control/:id"
                element={<CoordinatedControlResultsPage />}
            />
            <Route
                path="/mesoscopic-modeling/modeling-results"
                element={<ModelingResultsPage />}
            />
            <Route path="/logging" element={<LoggingPage />} />
        </Routes>
    );
}
