import Map from 'ol/Map';
import { transform } from 'ol/proj';
import View from 'ol/View';
import { RefObject, useEffect, useState } from 'react';

type UseMapType = {
    MapRef?: RefObject<HTMLDivElement> | null;
};

const useCreateMap = ({ MapRef }: UseMapType) => {
    const [isInit, setInit] = useState(false);
    const [mapValue, setMapValue] = useState<Map | null>(null);

    useEffect(() => {
        if (MapRef?.current && !isInit) {
            setInit(true);
            const map = new Map({
                target: MapRef.current,
                view: new View({
                    center: transform(
                        [45.02949963234971, 53.20212750229365],
                        'EPSG:4326',
                        'EPSG:3857'
                    ),
                    zoom: 13,
                }),
            });
            setMapValue(map);
        }
    }, [MapRef, isInit]);

    return {
        mapValue,
    };
};

export default useCreateMap;
