import { emptySplitApi } from '../../../../api/api';

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getTokenMap: build.mutation({
            query: () => ({
                url: `${process.env.REACT_APP_MAP_LINK_FOR_TOKEN}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetTokenMapMutation } = extendedApi;
