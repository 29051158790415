import { Link } from 'react-router-dom';
import { getClassName } from 'utils/helpers';

import { MenuItem } from '../../../molecules/menuItem/UI/menuItem';
import useMenu from '../logic/useBurgerMenu';
import styles from './burgerMenu.module.css';

export default function BurgerMenu() {
    const {
        handleChangeVisibleAuthNestedMenu,
        handleChangeVisibleNestedMenu,
        handleCloseBurgerMenu,
        visible,
        visibleAuthNestedMenu,
        ref,
        isOpen,
        handleLogout,
    } = useMenu();

    const showModelingResult = !!JSON.parse(
        localStorage.getItem('permissions') || '[]'
    ).find((word: string) => word.indexOf('show') !== -1);

    if (!isOpen) {
        return null;
    }
    return (
        <nav className={styles.menu} ref={ref}>
            <ul className={styles.menuBlock}>
                <Link to="/" className={styles.link}>
                    <MenuItem
                        className="unselectable"
                        text="Мониторинг состояния объектов"
                        onClick={handleCloseBurgerMenu}
                    />
                </Link>
                <MenuItem
                    className="unselectable"
                    text="Мезоскопическое моделирование"
                    onClick={handleChangeVisibleNestedMenu}
                    isVisibleArrow
                    isOpen={visible}
                />
                <ul
                    className={getClassName(
                        styles.nestedBlock,
                        visible ? styles.visible : styles.hide,
                        'unselectable'
                    )}
                >
                    <Link
                        to="/mesoscopic-modeling/local-calculations"
                        className={styles.link}
                    >
                        <MenuItem
                            text="Расчет локальной программы"
                            className={getClassName(
                                styles.nestedItem,
                                'unselectable'
                            )}
                            onClick={handleCloseBurgerMenu}
                        />
                    </Link>
                    <Link
                        to="/mesoscopic-modeling/coordinated-control"
                        className={styles.link}
                    >
                        <MenuItem
                            text="Расчет программы координированного управления"
                            className={getClassName(
                                styles.nestedItem,
                                'unselectable'
                            )}
                            onClick={handleCloseBurgerMenu}
                        />
                    </Link>
                    {showModelingResult && (
                        <Link
                            to="/mesoscopic-modeling/modeling-results"
                            className={styles.link}
                        >
                            <MenuItem
                                text="Результаты моделирования"
                                className={getClassName(
                                    styles.nestedItem,
                                    'unselectable'
                                )}
                                onClick={handleCloseBurgerMenu}
                            />
                        </Link>
                    )}
                </ul>
                <Link to="/logging" className={styles.link}>
                    <MenuItem
                        className="unselectable"
                        text="Журнал логирования"
                        onClick={handleCloseBurgerMenu}
                    />
                </Link>

                <ul className={styles.menuBlock}>
                    <MenuItem
                        className="unselectable"
                        text="Мой аккаунт"
                        onClick={handleChangeVisibleAuthNestedMenu}
                        isVisibleArrow
                        isOpen={visibleAuthNestedMenu}
                    />
                    <ul
                        className={getClassName(
                            styles.nestedBlock,
                            visibleAuthNestedMenu
                                ? styles.visible
                                : styles.hide,
                            'unselectable'
                        )}
                    >
                        <MenuItem
                            text="Выйти"
                            className={getClassName(
                                styles.nestedItem,
                                'unselectable'
                            )}
                            onClick={handleLogout}
                        />
                    </ul>
                </ul>
            </ul>
        </nav>
    );
}
