import ButtonAuthWrapper from 'components/molecules/buttonAuthWrapper/UI/buttonAuthWrapper';

import useAuthPage from '../logic/useAuthPage';
import styles from './authPage.module.css';

function AuthPage() {
    const { handleClick } = useAuthPage();
    return (
        <div className={styles.App}>
            <div className={styles.authWrapper}>
                <div className={styles.title}>
                    Модуль транспортного прогнозирования и моделирования
                </div>
                <ButtonAuthWrapper onClick={handleClick} />
            </div>
        </div>
    );
}

export default AuthPage;
