import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';

import AuthorizationWrapper from 'components/organisms/authWrapper/authWrapper';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { RoutesPaths } from 'Router/Router';

import MainPage from './components/pages/MainPage/UI/MainPage';
import { store } from './store/store';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Suspense fallback={null}>
                    <AuthorizationWrapper>
                        <MainPage>
                            <RoutesPaths />
                        </MainPage>
                    </AuthorizationWrapper>
                </Suspense>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
