import 'ol/ol.css';
import './map.css';

import { getClassName } from 'utils/helpers';

import ButtonHint from '../../../atoms/ButtonHint/UI/ButtonHint';
import useMap from '../logic/useMap';

type GISMapProps = {
    className?: string;
    isChecking?: boolean;
};

function GISMap({ className = '', isChecking = false }: GISMapProps) {
    const {
        MapRef,
        OverlayRef,
        handlerClosePoUp,
        MapPopupContentRef,
        handlerResetLayer,
        isVisibleResetBtn,
    } = useMap({ isChecking });
    return (
        <div className="map-wrapper">
            {!isChecking && (
                <ButtonHint
                    onClick={handlerResetLayer}
                    visible={isVisibleResetBtn}
                />
            )}
            <div
                id="mapGis"
                className={getClassName('map', className)}
                ref={MapRef}
            />
            <div id="mapPopup" className="ol-popup" ref={OverlayRef}>
                <div className="ol-popup-closer">
                    <svg
                        className="w3"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={handlerClosePoUp}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>
                <div id="mapPopupContent" ref={MapPopupContentRef} />
            </div>
        </div>
    );
}

export default GISMap;
