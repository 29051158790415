import { emptySplitApi } from '../../../../api/api';

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getLogout: build.query({
            query: () => ({
                url: `${process.env.REACT_APP_LOGOUT_LINK}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useLazyGetLogoutQuery } = extendedApi;
