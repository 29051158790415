import CountingDaysCheckboxes from 'components/molecules/countingDaysCheckboxes/UI/CountingDaysCheckboxes';
import GISMap from 'components/molecules/map/UI/map';
import TimeLag from 'components/molecules/timeLag/UI/TimeLag';
import { Button } from 'react-bootstrap';
import { getClassName } from 'utils/helpers';

import useOptionsPage from '../logic/useOptionsPage';
import styles from './optionsPage.module.css';

function OptionsPage() {
    const {
        handleChangeHolyDaysValue,
        handleChangeTimeLagValue,
        handleChangePage,
        handleChangeWorkDaysValue,
        holyDaysValue,
        workDaysValue,
    } = useOptionsPage();

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.wrapper}>
                <TimeLag onChange={handleChangeTimeLagValue} />
                <CountingDaysCheckboxes
                    workDaysValue={workDaysValue}
                    handleChangeWorkDaysValue={handleChangeWorkDaysValue}
                    holyDaysValue={holyDaysValue}
                    handleChangeHolyDaysValue={handleChangeHolyDaysValue}
                />
                <div className={styles.buttonWrapper}>
                    <Button variant="dark" onClick={handleChangePage}>
                        Получить
                    </Button>
                </div>
            </div>
            <div className={getClassName(styles.text, 'unselectable')}>
                Выберите объект
            </div>
            <GISMap isChecking className={styles.map} />
        </div>
    );
}

export default OptionsPage;
