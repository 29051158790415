import dayjs from 'dayjs';

import styles from './trafficIntensityBlock.module.css';

interface TrafficIntensityBlockProps {
    intensity?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isWatch?: boolean;
}

function TrafficIntensityBlock({
    intensity,
    onChange,
    isWatch = false,
}: TrafficIntensityBlockProps) {
    let keys;
    if (isWatch && intensity?.response) {
        keys = Object.keys(intensity.response);
    }
    if (!isWatch && intensity?.response?.response) {
        keys = Object.keys(intensity?.response.response);
    }
    return (
        <div className={styles.trafficIntensityWrapper}>
            <div className={styles.title}>Транспортная интенсивность</div>
            <div className={styles.timesRadioWrapper}>
                {keys?.map(
                    (item: any) =>
                        item !== 'result_id' && (
                            <div className={styles.timesWrapper}>
                                <label className={styles.text} htmlFor={item}>
                                    {dayjs(item).format('HH:mm:ss')}
                                </label>
                                <input
                                    className={`form-check-input ${styles.radioButton}`}
                                    type="radio"
                                    name="flexRadioDefault"
                                    id={item}
                                    onChange={onChange}
                                    value={item}
                                />
                            </div>
                        )
                )}
            </div>
        </div>
    );
}

export default TrafficIntensityBlock;
