import dayjs from 'dayjs';
import { Form } from 'react-bootstrap';
import { Flow, LaneFlowResponse } from 'utils/types';

import styles from './trafficIntensityBlockInputs.module.css';

interface TrafficIntensityBlockInputsProps {
    isChanged?: string | null;
    intensity: Flow[] | LaneFlowResponse[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

function TrafficIntensityBlockInputs({
    isChanged,
    intensity,
    onChange,
}: TrafficIntensityBlockInputsProps) {
    return (
        <div className={styles.trafficIntensityWrapper}>
            <div className={styles.timesRadioWrapper}>
                {intensity?.map(
                    (item: Flow | LaneFlowResponse, index: number) => (
                        <div className={styles.timesWrapper}>
                            <div className={styles.text}>
                                {dayjs(item.time).format('DD-MM-YYYY HH:mm:ss')}
                            </div>
                            <Form className={styles.form}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        disabled={Boolean(isChanged)}
                                        step="0.01"
                                        className={styles.input}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => onChange(e, index)}
                                        value={item?.count}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default TrafficIntensityBlockInputs;
