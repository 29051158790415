import { Pagination, Table } from 'antd';
import ModalWindow from 'components/atoms/Modal/UI/Modal';

import useLoggingPage from '../logic/useLoggingPage';
import styles from './loggingPage.module.css';

function LoggingPage() {
    const {
        columns,
        dataLogging,
        detail,
        show,
        handleClose,
        results,
        currentPage,
        setCurrentPage,
    } = useLoggingPage();

    return (
        <div className={styles.mainWrapper}>
            {detail && (
                <ModalWindow
                    show={show}
                    handleClose={handleClose}
                    title="Подробности"
                    body={
                        <div>
                            Ответ:
                            <textarea
                                className={styles.txtArea}
                                readOnly
                                value={JSON.stringify(detail, null, 4)}
                            />
                        </div>
                    }
                    footer={
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleClose}
                        >
                            Закрыть
                        </button>
                    }
                />
            )}
            <Table
                columns={columns}
                dataSource={dataLogging}
                loading={results.isLoading}
                pagination={false}
            />
            {dataLogging && (
                <Pagination
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    onChange={(page) => {
                        setCurrentPage(page - 1);
                    }}
                    defaultCurrent={1}
                    current={currentPage + 1}
                    total={Number(results.data?.response?.amount_pages) * 10}
                />
            )}
        </div>
    );
}

export default LoggingPage;
