import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { useLazyGetLoggingQuery } from '../requests/requests';
import styles from '../UI/loggingPage.module.css';

type DetailType = {
    flag?: string;
    zone_id?: number;
};

type LogResultsType = {
    id: number;
    created_on: string;
    user: number;
    event: string;
    detail: DetailType;
};

const useLoggingPage = () => {
    const [show, setShow] = useState<boolean>(false);
    const [dataLogging, setDataLogging] = useState<LogResultsType[]>([]);
    const [detail, setDetail] = useState<DetailType>();
    const [currentPage, setCurrentPage] = useState(0);

    const [getLogging, results] = useLazyGetLoggingQuery();

    const handleGetLogging = useCallback(
        async (page) => {
            await getLogging({
                size: 10,
                page: page + 1,
            });
        },
        [getLogging]
    );

    useEffect(() => {
        handleGetLogging(currentPage);
    }, [handleGetLogging, currentPage]);

    useEffect(() => {
        if (results) {
            setDataLogging(results.data?.response?.logs);
        }
    }, [results]);

    const handleShow = (currentDetail: DetailType) => {
        setShow(true);
        setDetail(currentDetail);
    };
    const handleClose = () => {
        setShow(false);
        setDetail(undefined);
    };

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'created_on',
            key: 'created_on',
            id: 'created_on',
            sorter: false,
            render: (text: string) => (
                <div>{dayjs(`${text}Z`).format('DD-MM-YYYY HH:mm:ss')}</div>
            ),
        },
        {
            title: 'Пользователь',
            dataIndex: 'user',
            key: 'user',
            id: 'user',
            sorter: false,
        },
        {
            title: 'Событие',
            dataIndex: 'event',
            key: 'event',
            id: 'event',
            sorter: false,
            render: (text: string, record: any) => (
                <div className={styles.eventCell}>
                    <span
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => handleShow(record?.detail)}
                        onClick={() => handleShow(record?.detail)}
                        className={styles.eventCellText}
                    >
                        {text}
                    </span>
                </div>
            ),
        },
    ];
    return {
        columns,
        dataLogging,
        detail,
        show,
        handleClose,
        results,
        currentPage,
        setCurrentPage,
    };
};

export default useLoggingPage;
