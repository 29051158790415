import Modal from 'react-bootstrap/Modal';

type ModalProps = {
    show?: boolean;
    handleClose?: () => void;
    title?: string;
    body?: React.ReactNode;
    footer?: React.ReactNode;
    size?: 'sm' | 'lg' | 'xl';
};

function ModalWindow({
    show = false,
    handleClose,
    title = '',
    body,
    footer,
    size,
}: ModalProps) {
    return (
        <Modal show={show} onHide={handleClose} size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </Modal>
    );
}

export default ModalWindow;
