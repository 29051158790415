import BurgerMenu from 'components/organisms/burgerMenu/UI/burgerMenu';
import Menu from 'components/organisms/menu/UI/menu';

import Header from '../../../organisms/Header/UI/Header';
import { useGetUserInformationQuery } from '../requests/requests';
import styles from './mainPage.module.css';

type MainPageProps = {
    children?: JSX.Element | null;
};

function MainPage({ children }: MainPageProps) {
    const { data: user } = useGetUserInformationQuery({
        refetchOnMountOrArgChange: true,
    });

    if (user) {
        const result = user.permissions
            .filter((word: string) => word.indexOf('forecast') !== -1)
            .map((word: string) => word.split('.')[2]);

        localStorage.setItem('permissions', JSON.stringify(result));
    }
    return (
        <div className={styles.App}>
            <Menu />
            <BurgerMenu />
            <div className={styles.wrapper}>
                <Header name={user?.name} />
                {children}
            </div>
        </div>
    );
}

export default MainPage;
