import Checkbox from 'components/atoms/Checkbox/UI/Checkbox';
import SliderBlock from 'components/molecules/sliderBlock/UI/sliderBlock';
import Form from 'react-bootstrap/Form';
import { getClassName } from 'utils/helpers';

import styles from './modelingOptionsBlock.module.css';

interface ModelingOptionsBlockProps {
    sliderValue?: number;
    handleChangeSliderValue?: (e: number) => void;
    smoothingValue?: boolean;
    handleChangeSmoothingValue?: () => void;
    amountProgramsValue?: string;
    amountProgramsChecked?: boolean;
    handleChangeAmountProgramsValue?: (
        e: React.ChangeEvent<HTMLInputElement>
    ) => void;
    handleChangeAmountProgramsChecked?: () => void;
}

function ModelingOptionsBlock({
    sliderValue,
    handleChangeSliderValue,
    smoothingValue,
    handleChangeSmoothingValue,
    amountProgramsValue,
    amountProgramsChecked,
    handleChangeAmountProgramsValue,
    handleChangeAmountProgramsChecked,
}: ModelingOptionsBlockProps) {
    return (
        <>
            <div className={styles.title}>Параметры моделирования</div>
            <div className={styles.wrapper}>
                <SliderBlock
                    value={sliderValue}
                    onChange={handleChangeSliderValue}
                />
                <div className={styles.countingProgramWrapper}>
                    <Checkbox
                        label="Сократить количество программ до"
                        type="checkbox"
                        id="programCount"
                        className={getClassName(
                            styles.checkbox,
                            'unselectable'
                        )}
                        checked={amountProgramsChecked}
                        onChange={handleChangeAmountProgramsChecked}
                    />
                    <Form className={styles.form}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                readOnly
                                className={styles.input}
                                value={amountProgramsValue}
                                onChange={handleChangeAmountProgramsValue}
                            />
                        </Form.Group>
                    </Form>
                </div>
                <Checkbox
                    label="Сгладить данные"
                    type="checkbox"
                    id="data"
                    className="unselectable"
                    checked={smoothingValue}
                    onChange={handleChangeSmoothingValue}
                />
            </div>
        </>
    );
}

export default ModelingOptionsBlock;
