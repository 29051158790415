import { Pagination, Table } from 'antd';

import useModelingResultsPage from '../logic/useModelingResultsPage';
import styles from './modelingResultsPage.module.css';

function ModelingResultsPage() {
    const { columns, results, dataResults, currentPage, setCurrentPage } =
        useModelingResultsPage();
    return (
        <div className={styles.mainWrapper}>
            <Table
                columns={columns}
                dataSource={dataResults}
                loading={results.isLoading}
                pagination={false}
            />
            {dataResults && (
                <Pagination
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    onChange={(page) => {
                        setCurrentPage(page - 1);
                    }}
                    defaultCurrent={1}
                    current={currentPage + 1}
                    total={Number(results.data?.response?.amount_pages) * 10}
                />
            )}
        </div>
    );
}

export default ModelingResultsPage;
