import { setIsAuthorization } from 'components/organisms/authWrapper/reducers/authorization';
import { setIsOpen } from 'components/organisms/burgerMenu/reducers/isOpenBurgerMenu';
import { useAppDispatch, useAppSelector } from 'hooks/defaulthooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useLazyGetLogoutQuery } from '../requests/requests';

const useHeader = () => {
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const [logout, params] = useLazyGetLogoutQuery();
    const isOpen = useAppSelector((state) => state.burgerMenu.isOpen);
    const [open, setOpen] = useState(isOpen);

    const handleLogout = async () => {
        await logout({});
    };

    useEffect(() => {
        if (params.isSuccess) {
            dispatch(setIsAuthorization({ isAuth: false }));
            localStorage.clear();
        }
    }, [dispatch, params]);

    const handleChangeVisibleBurgerMenuOpen = () => {
        setOpen(true);
        dispatch(setIsOpen(true));
    };
    const handleChangeVisibleBurgerMenuClose = () => {
        setOpen(false);
        dispatch(setIsOpen(false));
    };

    return {
        pathname,
        handleLogout,
        isOpen,
        open,
        handleChangeVisibleBurgerMenuClose,
        handleChangeVisibleBurgerMenuOpen,
    };
};

export default useHeader;
