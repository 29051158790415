import { emptySplitApi } from '../../../../api/api';

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getUserInformation: build.query({
            query: () => ({
                url: `${process.env.REACT_APP_USER_LINK}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetUserInformationQuery } = extendedApi;
